import * as React from "react"
import Layout from "../layouts"
import Seo from "../components/seo"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  BreadCrumb,
  Container,
  Section,
  SectionTitle,
  SectionDescription,
  MarkdownContent,
  SectionPageTitle,
} from "../components/Section"
import {
  ListInfoContainer,
  ListInfo,
  ListInfoItem,
  CardInfo,
  CardIcon,
  CardDesc,
} from "../components/Benefits"
import ExpertSection from "../components/Expert"
import ArticleCard from "../components/ArticleCard"
import BreakpointUp from "../components/Media/BreakpointUp"

const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`
const SectionGridCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.lg` 
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  `}
`
const CardIcons = styled(CardIcon)`
  margin-bottom: 10px;
  width: 100%;
  @media (min-width: 1280px) {
    margin-bottom: 0;
    width: auto;
  }
`
const ServiceAreaPage = ({location, data }) => (
  <Layout isHeader={true} location={location}>
    <Seo
      title="Service Area - See where Liberty Metal Structures delivers"
      description="Liberty Metal Structures provides free delivery and installation in 20 states of the USA. Select your state here and get your metal building delivered."
    />
    <BreadCrumb>
      <Container maxWidth="100%" pl="3%" pr="3%">
        <Link to="/">Home</Link>
        <span>Service Area</span>
      </Container>
    </BreadCrumb>
    <Section xpt="170px" mpt="150px" pt="120px" pb="0" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionPageTitle mb="10px">Our Service Areas</SectionPageTitle>
        <SectionDescription maxWidth="1496px" mb="40px">
          <p>
            Liberty Metal Structures is proud to be an American company! We
            provide free delivery and installation to over 20 states of the
            United States. Select yours here:
          </p>
        </SectionDescription>
      </Container>
    </Section>

    <Section
      xpt="90px"
      mpt="60px"
      pt="30px"
      pb="0"
      bgColor="#033366"
      textAlign="center"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <StaticImage
          src="../images/map.png"
          placeholder="blurred"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Map"
        />
      </Container>
    </Section>

    <Section
      xpt="90px"
      mpt="60px"
      pt="30px"
      xpb="90px"
      mpb="60px"
      pb="30px"
      bgColor="#fff"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionTitle mb="20px" maxWidth="1496px">
          Our Office
        </SectionTitle>
        <MarkdownContent maxWidth="1496px">
          <ListInfoContainer>
            <ListInfo>
              <ListInfoItem>
                <CardInfo>
                  <CardIcons>
                    <StaticImage
                      src="../svg/location.svg"
                      placeholder="blurred"
                      alt="location"
                    />
                  </CardIcons>
                  <CardDesc>
                    <strong>Address</strong>
                    <p>796 W Lebanon St. Mount Airy, NC 27030</p>
                  </CardDesc>
                </CardInfo>
              </ListInfoItem>
              <ListInfoItem>
                <CardInfo>
                  <CardIcons>
                    <StaticImage
                      src="../svg/email.svg"
                      placeholder="blurred"
                      alt="email"
                    />
                  </CardIcons>
                  <CardDesc>
                    <strong>Email Address</strong>
                    <p>
                      <a href="mailto:sales@libertymetalstructures.com">
                        sales@libertymetalstructures.com
                      </a>
                    </p>
                  </CardDesc>
                </CardInfo>
              </ListInfoItem>
              <ListInfoItem>
                <CardInfo>
                  <CardIcons>
                    <StaticImage
                      src="../svg/send.svg"
                      placeholder="blurred"
                      alt="send"
                    />
                  </CardIcons>
                  <CardDesc>
                    <strong>Contact Number</strong>
                    <p>
                      <a href="tel:8004407309">(800) 440-7309</a>
                    </p>
                  </CardDesc>
                </CardInfo>
              </ListInfoItem>
            </ListInfo>
          </ListInfoContainer>
        </MarkdownContent>
      </Container>
    </Section>

    <Section
      xpt="90px"
      mpt="60px"
      pt="30px"
      xpb="30px"
      mpb="10px"
      pb="0"
      bgColor="transparent"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionTitle>The Call of Liberty: Our Blog</SectionTitle>
        <SectionDescription maxWidth="1496px">
          <p>
            Discover the latest science and the hottest trends here! Our blog is
            regularly updated to keep you informed. Subscribe today!
          </p>
        </SectionDescription>
        <SectionGrid>
          {data.allContentfulBlog.edges.map((item, i) => {
            if (i < 3) {
              return (
                <SectionGridCol key={i}>
                  <ArticleCard data={item.node} />
                </SectionGridCol>
              )
            }
            return <></>
          })}
        </SectionGrid>
      </Container>
    </Section>

    <ExpertSection />
  </Layout>
)

export default ServiceAreaPage

export const query = graphql`
  query ServiceAreaPageQuery {
    allContentfulBlog {
      edges {
        node {
          title
          url
          category {
            name
          }
          publishDate(fromNow: true)
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
